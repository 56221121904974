export type Delimiter = {
  type: 'delimiter';
  name: string;
};
export type Product = {
  type: 'product';
  name: string;
  price: string;
};

export type IProductList = Delimiter | Product;

export const productList: IProductList[] = [
  { type: 'delimiter', name: 'Страйкбольная пиротехника' },
  { type: 'product', name: 'Граната РСГ-1 (горох)', price: '80.00' },
  {
    type: 'product',
    name: 'Мина страйкбольная дистанционная МС-Д (горох)',
    price: '120.00',
  },
  { type: 'product', name: 'Мина электронная "ШАЙБА" (мел)', price: '110.00' },
  { type: 'product', name: 'Дымовая шашка малая ДШ-М', price: '100.00' },
  {
    type: 'product',
    name: 'Дымовая шашка ДШ-С (100 гр)  ',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Дымовая шашка классическая ДШ-К (150 гр)',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Дымовая шашка стандартная ДШ-С (200 гр)',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Дымовая шашка большая ДШ-Б (300 гр)',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Дымовая шашка ДШ-500 (500 гр)',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Выстрел подствольный "Удар-Г" (горох)',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Выстрел подствольный дымовой "Удар-Д"',
    price: '(предзаказ)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный стандартный ВМС-1 (100 м) (в разработке) ',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный средний ВМС-2 (150 м) (в разработке) ',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный Увеличенной дальности ВМ-УД (до 250 м) ( в разраб) ',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный стандартный игла ВМС-1И (100 м)',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный средний игла ВМС-2И (100 м)',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный увел. Дальн. игла ВМ-УДИ (100 м) (в разработке) ',
    price: '(в разработке)',
  },
  {
    type: 'product',
    name: 'Выстрел минометный дымовой ВМ-Д',
    price: '(в разработке)',
  },
  { type: 'delimiter', name: 'Пусковые устройства' },
  {
    type: 'product',
    name: 'Пусковое устройство Модель 4х1 (под заказ)',
    price: '2,500.00',
  },
  {
    type: 'product',
    name: 'Пусковое устройство Модель 4х2 (под заказ)',
    price: '3,000.00',
  },
  {
    type: 'product',
    name: 'Пусковое устройство Модель 4х4 (под заказ)',
    price: '3,500.00',
  },
  {
    type: 'product',
    name: 'Пусковое устройство Модель 2х2 (под заказ)',
    price: '2,300.00',
  },
  {
    type: 'product',
    name: 'Пусковое устройство Модель 2х1 (под заказ)',
    price: '1,900.00',
  },
  {
    type: 'product',
    name: 'Подствольный гранатомет',
    price: '(в разработке)',
  },
  { type: 'product', name: 'Миномет М-50', price: '(в разработке)' },
  { type: 'product', name: 'Миномет игла МИ-50', price: '(в разработке)' },
  {
    type: 'delimiter',
    name: 'Армейская пиротехника (временно отпускается по розничной цене)',
  },
  {
    type: 'product',
    name: 'Сигнальная ракета картон (Кр.Зел.Ос.Дневн) РСП РОП',
    price: '500.00',
  },
  {
    type: 'product',
    name: 'Сигнальный патрон пластик (Кр.Зел.Ос) РОПуд',
    price: '550.00',
  },
  {
    type: 'product',
    name: 'Наземный сигнальный патрон фаера (Кр.Зел.Желт) НСП',
    price: '500.00',
  },
  {
    type: 'product',
    name: 'Наземный сигнальный патрон оранж дым НСП',
    price: '500.00',
  },
  {
    type: 'product',
    name: 'Сигнал химической тревоги СХТ-40',
    price: '650.00',
  },
  {
    type: 'product',
    name: 'Плавучая дымовая шашка оранжевый дым 3 мин ПДШ			1,200.00 ',
    price: '',
  },
  { type: 'product', name: 'Дымовая шашка РДГ-2б', price: '500.00' },
  { type: 'product', name: 'Дымовая шашка ДМ-11', price: '(под заказ)' },
  { type: 'product', name: 'Дымовая шашка УДШ', price: '(под заказ)' },
  {
    type: 'product',
    name: 'Дымовая шашка большая БДШ 5',
    price: '(под заказ)',
  },
];
